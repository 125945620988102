import React, { useEffect } from 'react';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { Helmet } from 'react-helmet';
import Introduction from './Introduction';
import MainFeatures from './MainFeatures';
import HowWeHelp from './HowWeHelp';
import WhatToExpectSoon from './WhatToExpectSoon';

const Homepage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return(
    <>
      <Helmet>
        <title>Ringgit Lab Elite: Makes Stock Investing Easier</title>
        <meta name="description" content="Ringgit Lab Elite provides one-stop supporting platform for stock trading, an exclusive service to registered clients with Zulhilmi Zolkfli MPlus Remisier." />
      </Helmet>
      <Header />
      <Introduction />
      <MainFeatures />
      <HowWeHelp />
      <WhatToExpectSoon />
      <Footer />
    </>
  )
}

export default Homepage;