import React from 'react';
import { Container, Button, Row, Col } from 'reactstrap';
import RinggitLabMacbookImg from '../../assets/ringgitlab-macbook.png';
import Slide from 'react-reveal/Slide';

const Introduction = () => {

  return(
    <Container className="mt-5 mb-5">
      <Row className="pt-5">
        <Col md={6} sm={12} xs={12} className="pt-5 pb-5">
          <Slide bottom>
            <h1 className="text-md-left text-center font-weight-bold" style={{ fontSize: '36px' }}>
              Ringgit Lab Elite
            </h1>
            <h2 className="text-md-left text-center mt-4 pb-3 grey-color font-weight-bold" style={{ fontSize: '32px' }}>
              Makes Stock Investing Easier
            </h2>
            <h2 className="text-md-left text-center grey-color mt-5 pb-3" style={{ fontSize: '24px' }}>
              Ringgit Lab Elite provides one-stop supporting platform for stock trading, an exclusive service to registered clients with Zulhilmi Zolkfli MPlus.
            </h2>
            <div className="mt-5 text-md-left text-center">
              <a href="https://www.zulhilmizolkfli.com/mplus-online-cds-registration" rel="noopener noreferrer" target="_blank">
                <h3 className="mb-0">
                  <Button className="primary-btn header-pill font-weight-bold">Open CDS account</Button>
                </h3>
              </a>
            </div>
          </Slide>
        </Col>
        <Col md={6} sm={12} xs={12} className="align-self-center pt-5 pb-5">
          <Slide bottom>
            <img alt="Ringgit Lab" src={RinggitLabMacbookImg} style={{ width: '100%', objectFit: 'fill', borderRadius: '10px' }} />
          </Slide>
        </Col>
      </Row>
    </Container>
  )
}

export default Introduction;