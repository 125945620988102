import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons';

const FooterSNS = () => {
  return(
    <ul className="social-icon">
      <li>
        <a className="twitter" href="https://twitter.com/ringgitlabelite" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={ faTwitter } /></a>
      </li>
      <li>
        <a className="facebook" href="https://www.facebook.com/ringgitlabelite" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={ faFacebookF } /></a>
      </li>
      <li>
        <a className="instagram" href="https://www.instagram.com/ringgitlabelite/" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={ faInstagram } /></a>
      </li>
      <li>
        <a className="tiktok" href="https://www.tiktok.com/@ringgitlabelite" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={ faTiktok } /></a>
      </li>
    </ul>
  );
};

export default FooterSNS;
