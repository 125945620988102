import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import FooterSNS from './FooterSNS';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const title = {
    fontSize: "32px",
    color: '#fff',
    fontFamily: '"Montserrat", sans-serif',
  };

  return(
    <>
      <div className="footer">
        <Container>
          <div className="upper-section-footer">
            <Row>
              <Col xl={6} lg={6} md={6} sm={12} xs={12} className="mb-5">
                <h2 style={title} className="font-weight-bold pb-3">Ringgit Lab Elite</h2>
                <FooterSNS />
              </Col>
              <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mb-5" style={{ fontSize: '18px' }}>
                <h3 style={{ fontSize: '18px', fontFamily: '"Montserrat", sans-serif' }} className="font-weight-bold mb-5">Ringgit Lab Elite</h3>
                <Link className="footer-link mb-4" to="/">Home</Link>
                <Link className="footer-link mb-4" to="/stock-screener">Stock Screener</Link>
                <Link className="footer-link mb-4" to="/trading-journal">Trading Journal</Link>
                <Link className="footer-link mb-4" to="/paper-trading">Paper Trading</Link>
                <a className="footer-link mb-4" href="https://ringgitlabelite.notion.site/Ringgit-Lab-Elite-FAQs-ba4df99417764761848f853bf56c527c" rel="noopener noreferrer" target="_blank">Help Center (English)</a>
                <a className="footer-link mb-4" href="https://ringgitlabelite.notion.site/Soalan-Lazim-Berkenaan-Ringgit-Lab-Elite-5093647fdc9e420299e4fb353715902f" rel="noopener noreferrer" target="_blank">Help Center (Bahasa)</a>
              </Col>
              <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mb-5" style={{ fontSize: '18px' }}>
                <h3 style={{ fontSize: '18px', fontFamily: '"Montserrat", sans-serif' }} className="font-weight-bold mb-5">Discover</h3>
                <a className="footer-link mb-4" href="https://www.ringgitlab.com" rel="noopener noreferrer" target="_blank">Ringgit Lab</a>
                <a className="footer-link mb-4" href="https://www.ringgitlab.com/stocks" rel="noopener noreferrer" target="_blank">Stock Search</a>
                <a className="footer-link mb-4" href="https://www.ringgitlab.com/market-news" rel="noopener noreferrer" target="_blank">Market News</a>
                <a className="footer-link mb-4" href="https://www.ringgitlab.com/brokerage-calculator" rel="noopener noreferrer" target="_blank">Calculator</a>
                <Link className="footer-link mb-4" to="/terms">Terms of Service</Link>
                <Link className="footer-link mb-4" to="/privacy-policy">Privacy Policy</Link>
              </Col>
            </Row>
          </div>
          <div className="divider"></div>
          <div className="copyright">
            <p>Copyright &copy; {currentYear} <span><a href="https://elite.ringgitlab.com"><strong>Ringgit Lab Elite</strong></a></span> (AS0438257-V). All rights reserved.</p>
          </div>
        </Container>
      </div>
    </>
  )
}

export default Footer;