import React from 'react';
import { Container, Modal, ModalBody, ModalHeader } from 'reactstrap';
import TelegramChannelImg from '../../assets/watchlist.png';

interface Props {
  open: boolean;
  toggle: () => void;
}

export const WatchListModal: React.FC<Props> = (props) => {

  return (
    <>
      <Modal isOpen={props.open} toggle={props.toggle} size="lg">
        <ModalHeader toggle={props.toggle}>
          <span style={{ fontSize: '22px' }} className="font-weight-bold pt-2 pb-2">
            Watch List
          </span>
        </ModalHeader>
        <ModalBody  className="pb-5 mb-5">
          <Container className="pt-3 pb-5">
            <h3 style={{ fontSize: '18px' }} className="mb-3 grey-color">
              Save your favorite stocks and get notified (coming soon) when the current price hit your entry price.
            </h3>
            <img alt="Ringgit Lab Elite" src={TelegramChannelImg} style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', borderRadius: '10px' }} />
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
}