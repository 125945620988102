import React from 'react';
import { Route } from 'react-router-dom';
import StockScreenerPage from '../components/landing/StockScreenerPage';
import Homepage from '../components/landing/Homepage';
import PaperTradingPage from '../components/landing/PaperTradingPage';
import TradingJournalPage from '../components/landing/TradingJournalPage';
import HowToSignUpPage from '../components/landing/HowToSignUpPage';
import PrivacyPolicyPage from '../components/terms/PrivacyPolicyPage';
import TermsOfServicePage from '../components/terms/TermsOfServicePage';

const NoAuthRoutes = () => {
  return (
    <>
      <Route exact path="/" component={Homepage} />
      <Route exact path="/stock-screener" component={StockScreenerPage} />
      <Route exact path="/paper-trading" component={PaperTradingPage} />
      <Route exact path="/trading-journal" component={TradingJournalPage} />
      <Route exact path="/how-to-sign-up" component={HowToSignUpPage} />
      <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route exact path="/terms" component={TermsOfServicePage} />
    </>
  );
};

export default NoAuthRoutes;