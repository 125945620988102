import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container
} from 'reactstrap';
import Footer from '../footer/Footer';
import Header from '../header/Header';

const PrivacyPolicyPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const greyText = {
    color: '#656973',
  };

  const title = {
    fontSize: '18px',
    marginTop: '40px',
  };

  return (
    <>
      <Helmet>
        <title>Ringgit Lab Elite: Privacy Policy</title>
      </Helmet>
      <Header />
      <Container className="pt-5 pb-5">
        <h1 className="font-weight-bold mt-5">Privacy Policy</h1>
        <div className="divider"></div>
        <div>
          <p style={greyText}>Effective date: December 1, 2021</p>
          <p>Ringgit Lab Elite ("us", "we", or "our") operates Ringgit Lab Elite website including any subdomains thereof, and any other websites through which Ringgit Lab Elite makes its services available (collectively, "Site") and all associated services (collectively, "Ringgit Lab Elite Services"). The Site and Ringgit Lab Elite Services together are hereinafter collectively referred to as the "Ringgit Lab Elite Platform".</p>
          <p>This page informs you of our policies regarding the collection, use and disclosure of personal data when you use the Ringgit Lab Elite Platform and the choices you have associated with that data.</p>
          <p>We use your data to provide and improve the Ringgit Lab Elite Platform. By using the Ringgit Lab Elite Platform, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our <span><a className="link-text" href="https://elite.ringgitlab.com/terms" rel="noopener noreferrer" target="_blank">Terms and Conditions</a></span>.</p>

          <h2 style={title} className="font-weight-bold">Definitions</h2>
          <ul>
            <li className="mt-3">
              <p><strong>Personal Data</strong></p>
              <p>Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
            </li>
            <li className="mt-3">
              <p><strong>Usage Data</strong></p>
              <p>Usage Data is data collected automatically either generated by the use of the Ringgit Lab Elite Platform or from the Ringgit Lab Elite Platform infrastructure itself (for example, the duration of a page visit).</p>
            </li>
            <li className="mt-3">
              <p><strong>Cookies</strong></p>
              <p>Cookies are small files stored on your device (computer or mobile device).</p>
            </li>
            <li className="mt-3">
              <p><strong>Data Controller</strong></p>
              <p>Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed.</p>
              <p>For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.</p>
            </li>
            <li className="mt-3">
              <p><strong>Data Processors (or Service Providers)</strong></p>
              <p>Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller.</p>
              <p>We may use the services of various Service Providers in order to process your data more effectively.</p>
            </li>
            <li className="mt-3">
              <p><strong>Data Subject (or User)</strong></p>
              <p>Data Subject is any living individual who is using the Ringgit Lab Elite Platform and is the subject of Personal Data.</p>
            </li>
          </ul>

          <h2 style={title} className="font-weight-bold">Information Collection and Use</h2>
          <p>We collect several different types of information for various purposes to provide and improve the Ringgit Lab Elite Platform to you.</p>

          <h3 style={title} className="font-weight-bold">Types of Data Collected</h3>

          <h4 style={title} className="font-weight-bold">Personal Data</h4>
          <p>While using the Ringgit Lab Elite Platform, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Identity Card (IC) or passport number</li>
            <li>CDS number</li>
            <li>Client code</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Cookies and Usage Data</li>
            <li>Related information about stock trading</li>
          </ul>
          <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by unsubscribing from Settings on Ringgit Lab Elite Site.</p>

          <h4 style={title} className="font-weight-bold">Usage Data</h4>
          <p>We may also collect information on how the Ringgit Lab Elite Platform is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of the Ringgit Lab Elite Platform that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>

          <h4 style={title} className="font-weight-bold">Tracking & Cookies Data</h4>
          <p>We use cookies and similar tracking technologies to track the activity on the Ringgit Lab Elite Platform and we hold certain information.</p>
          <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyse the Ringgit Lab Elite Platform.</p>
          <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of the Ringgit Lab Elite Platform.</p>
          <p>Examples of Cookies we use:</p>
          <ul>
            <li><strong>Session Cookies.</strong> We use Session Cookies to operate the Ringgit Lab Elite Platform.</li>
            <li><strong>Preference Cookies.</strong> We use Preference Cookies to remember your preferences and various settings.</li>
            <li><strong>Security Cookies.</strong> We use Security Cookies for security purposes.</li>
          </ul>

          <h2 style={title} className="font-weight-bold">Use of Data</h2>
          <p>Ringgit Lab Elite uses the collected data for various purposes:</p>
          <ul>
            <li>To provide and maintain the Ringgit Lab Elite Platform</li>
            <li>To notify you about changes to the Ringgit Lab Elite Platform</li>
            <li>To allow you to participate in interactive features of the Ringgit Lab Elite Platform when you choose to do so</li>
            <li>To provide customer support</li>
            <li>To gather analysis or valuable information so that we can improve the Ringgit Lab Elite Platform</li>
            <li>To monitor the usage of the Ringgit Lab Elite Platform</li>
            <li>To detect, prevent and address technical issues</li>
            <li>To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information</li>
          </ul>

          <h2 style={title} className="font-weight-bold">Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)</h2>
          <p>If you are from the European Economic Area (EEA), Ringgit Lab Elite legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.</p>
          <p>Ringgit Lab Elite may process your Personal Data because:</p>
          <ul>
            <li>We need to perform a contract with you</li>
            <li>You have given us permission to do so</li>
            <li>The processing is in our legitimate interests and it is not overridden by your rights</li>
            <li>For payment processing purposes</li>
            <li>To comply with the law</li>
          </ul>

          <h2 style={title} className="font-weight-bold">Retention of Data</h2>
          <p>Ringgit Lab Elite will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies.</p>
          <p>Ringgit Lab Elite will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of the Ringgit Lab Elite Platform, or we are legally obligated to retain this data for longer periods.</p>

          <h2 style={title} className="font-weight-bold">Transfer of Data</h2>
          <p>Your information including Personal Data, may be transferred to - and maintained on - computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
          <p>If you are located outside Japan and choose to provide information to us, please note that we transfer the data including Personal Data, to Japan and process it there.</p>
          <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
          <p>Ringgit Lab Elite will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>

          <h2 style={title} className="font-weight-bold">Disclosure of Data</h2>

          <h3 style={title} className="font-weight-bold">Legal Requirements</h3>
          <p>Ringgit Lab Elite may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
          <ul>
            <li>To comply with a legal obligation</li>
            <li>To protect and defend the rights or property of Ringgit Lab Elite</li>
            <li>To prevent or investigate possible wrongdoing in connection with the Ringgit Lab Elite Platform</li>
            <li>To protect the personal safety of users of the Ringgit Lab Elite Platform or the public</li>
            <li>To protect against legal liability</li>
          </ul>

          <h2 style={title} className="font-weight-bold">Security of Data</h2>
          <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

          <h2 style={title} className="font-weight-bold">Our Policy on "Do Not Track" Signals under the California Online Protection Act (CalOPPA)</h2>
          <p>We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.</p>
          <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>

          <h2 style={title} className="font-weight-bold">Your Data Protection Rights under the General Data Protection Regulation (GDPR)</h2>
          <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights. Ringgit Lab Elite aims to take reasonable steps to allow you to correct, amend, delete or limit the use of your Personal Data.</p>
          <p>If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>
          <p>In certain circumstances, you have the following data protection rights:</p>
          <ul>
            <li>
              <p><strong>The right to access, update or delete the information we have on you.</strong> Whenever made possible, you can access, update or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you.</p>
            </li>
            <li>
              <p><strong>The right of rectification.</strong> You have the right to have your information rectified if that information is inaccurate or incomplete.</p>
            </li>
            <li>
              <p><strong>The right to object.</strong> You have the right to object to our processing of your Personal Data.</p>
            </li>
            <li>
              <p><strong>The right of restriction.</strong> You have the right to request that we restrict the processing of your personal information.</p>
            </li>
            <li>
              <p><strong>The right to data portability.</strong> You have the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format.</p>
            </li>
            <li>
              <p><strong>The right to withdraw consent.</strong> You also have the right to withdraw your consent at any time where Ringgit Lab Elite relied on your consent to process your personal information.</p>
            </li>
          </ul>
          <p>Please note that we may ask you to verify your identity before responding to such requests.</p>
          <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>

          <h2 style={title} className="font-weight-bold">Service Providers</h2>
          <p>We may employ third party companies and individuals to facilitate the Ringgit Lab Elite Platform ("Service Providers"), provide the Ringgit Lab Elite Platform on our behalf, perform Service-related services or assist us in analyzing how the Ringgit Lab Elite Platform is used.</p>
          <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>

          <h3 style={title} className="font-weight-bold">Analytics</h3>
          <p>We may use third-party Service Providers to monitor and analyse the use of the Ringgit Lab Elite Platform.</p>
          <ul>
            <li>
              <p><strong>Google Analytics</strong></p>
              <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.</p>
              <p>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.</p>
              <p>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy?hl=en" rel="noopener noreferrer" target="_blank">https://policies.google.com/privacy?hl=en</a></p>
            </li>
          </ul>

          <h3 style={title} className="font-weight-bold">Behavioral Remarketing</h3>
          <p>Ringgit Lab Elite uses re-marketing services to advertise on third party websites to you after you visited the Ringgit Lab Elite Platform. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to the Ringgit Lab Elite Platform.</p>
          <ul>
            <li className="mt-3">
              <p><strong>Google Ads (AdWords)</strong></p>
              <p>Google Ads (AdWords) re-marketing service is provided by Google Inc.</p>
              <p>You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: <a href="http://www.google.com/settings/ads" rel="noopener noreferrer" target="_blank">http://www.google.com/settings/ads</a></p>
              <p>Google also recommends installing the Google Analytics Opt-out Browser Add-on - <a href="https://tools.google.com/dlpage/gaoptout" rel="noopener noreferrer" target="_blank">https://tools.google.com/dlpage/gaoptout</a> - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.</p>
              <p>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: <a href="https://policies.google.com/privacy?hl=en" rel="noopener noreferrer" target="_blank">https://policies.google.com/privacy?hl=en</a></p>
            </li>
            <li className="mt-3">
              <p><strong>Facebook</strong></p>
              <p>Facebook re-marketing service is provided by Facebook Inc.</p>
              <p>You can learn more about interest-based advertising from Facebook by visiting this page: <a href="https://www.facebook.com/help/164968693837950" rel="noopener noreferrer" target="_blank">https://www.facebook.com/help/164968693837950</a></p>
              <p>To opt-out from Facebook's interest-based ads, follow these instructions from Facebook:  <a href="https://www.facebook.com/help/568137493302217" rel="noopener noreferrer" target="_blank">https://www.facebook.com/help/568137493302217</a></p>
              <p>Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA <a href="http://www.aboutads.info/choices/" rel="noopener noreferrer" target="_blank">http://www.aboutads.info/choices/</a>, the Digital Advertising Alliance of Canada in Canada <a href="http://youradchoices.ca/" rel="noopener noreferrer" target="_blank">http://youradchoices.ca/</a> or the European Interactive Digital Advertising Alliance in Europe <a href="http://www.youronlinechoices.eu/" rel="noopener noreferrer" target="_blank">http://www.youronlinechoices.eu/</a>, or opt-out using your mobile device settings.</p>
              <p>For more information on the privacy practices of Facebook, please visit Facebook's Data Policy: <a href="https://www.facebook.com/privacy/explanation" rel="noopener noreferrer" target="_blank">https://www.facebook.com/privacy/explanation</a></p>
            </li>
          </ul>

          <h3 style={title} className="font-weight-bold">Payments</h3>
          <p>We may provide paid products and/or services within the Ringgit Lab Elite Platform. In that case, we use third-party services for payment processing (e.g. payment processors).</p>
          <p>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
          <p>The payment processor we work with is:</p>
          <ul>
            <li className="mt-3">
              <p><strong>Stripe</strong></p>
              <p>Their Privacy Policy can be viewed at <a className="paragraph-link" href="https://stripe.com/us/privacy" rel="noopener noreferrer" target="_blank">https://stripe.com/us/privacy</a></p>
            </li>
          </ul>

          <h2 style={title} className="font-weight-bold">Links to Other Sites</h2>
          <p>The Ringgit Lab Elite Platform may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
          <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

          <h2 style={title} className="font-weight-bold">Children's Privacy</h2>
          <p>The Ringgit Lab Elite does not address anyone under the age of 18 ("Children").</p>
          <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>

          <h2 style={title} className="font-weight-bold">Changes to This Privacy Policy</h2>
          <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
          <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
          <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

          <h2 style={title} className="font-weight-bold">Contact Us</h2>
          <p>If you have any questions about this Privacy Policy, please contact us:</p>
          <ul className="pb-5">
            <li>By email: elite@ringgitlab.com</li>
          </ul>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;