import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Button } from 'reactstrap';
import RLEUsageImg from '../../assets/using-ringgit-lab-elite.png';
import Slide from 'react-reveal/Slide';
import Header from '../header/Header';
import Footer from '../footer/Footer';

const HowToSignUpPage = () => {

  return(
    <>
      <Helmet>
        <title>Ringgit Lab Elite: How To Sign Up?</title>
        <meta name="description" 
          content="Scared to pull the trigger on that first stock purchase? Looking for a way to start practicing before you put your actual, hard-earned money on the line? Start paper trading with Ringgit Lab." 
        />
      </Helmet>
      <Header />
      <Container className="pb-5 mt-5 pt-5">
        <Slide bottom>
          <h1 className="text-center mt-5 font-weight-bold">
            How to sign up Ringgit Lab Elite?
          </h1>
          <h2 className="pt-3 text-center grey-color" style={{ fontSize: '24px' }}>
            Ringgit Lab Elite is exclusive and limited to invitation only. Once you have open a CDS account with Zulhilmi Zolkfli, you'll get an invitation email to sign up Ringgit Lab Elite for free.{' '}
            <span>
              <a href="https://ringgitlabelite.notion.site/How-to-signup-Ringgit-Lab-Elite-fe16f706da62474da7aea8f21144a017" className="link-text" rel="noopener noreferrer" target="_blank">
                Learn more here.
              </a>
            </span>
          </h2>
          <img alt="Ringgit Lab Elite" className="mt-5" src={RLEUsageImg} style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem' }} />
          <div className="mt-5 text-center">
            <a href="https://www.zulhilmizolkfli.com/mplus-online-cds-registration" className="mb-3 mr-3" rel="noopener noreferrer" target="_blank">
              <h3 className="mb-0">
                <Button className="primary-btn font-weight-bold header-pill">Open CDS account</Button>
              </h3>
            </a>
          </div>
        </Slide>
      </Container>
      <Footer />
    </>
  )
}

export default HowToSignUpPage;