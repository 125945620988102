import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import ScreenerIcon from '../../assets/icons/idea.png';
import MaterialIcon from '../../assets/icons/target.png';
import ChannelIcon from '../../assets/icons/online-advertising.png';
import JournalIcon from '../../assets/icons/bar-graph.png';
import PaperIcon from '../../assets/icons/income-statement.png';
import AccountIcon from '../../assets/icons/security.png';
import { Link } from 'react-router-dom';
import { WatchListModal } from './WatchListModal';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import { LearningMaterialsModal } from './LearningMaterialsModal';

const MainFeatures = () => {
  const [openModalLearning, setOpenModalLearning] = useState(false);
  const [openModalWatchList, setOpenModalWatchList] = useState(false);

  const toggleModalLearning = () => {
    setOpenModalLearning(!openModalLearning);
  };

  const toggleModalWatchList = () => {
    setOpenModalWatchList(!openModalWatchList);
  };

  return(
    <>
      <Container className="pb-5">
        <div className="divider"></div>
        <Slide bottom>
          <h2 className="text-center font-weight-bold pt-5">
            What Ringgit Lab Elite offers?
          </h2>
          <h3 className="mt-3 pb-4 grey-color text-center" style={{ fontSize: '24px' }}>
            Everything you need to strengthen your stock trading <strong className="success">for free</strong>
          </h3>
        </Slide>
        <Row className="mt-5">
          <Col md={6} sm={12} xs={12} className="mb-5 text-center">
            <Fade top>
              <img src={ScreenerIcon} alt="Ringgit Lab Elite" style={{ width: '70px' }} />
              <Link to="/stock-screener" className="link-black">
                <h3 className="mt-4 font-weight-bold" style={{ fontSize: '24px' }}>
                  Potential Stock
                </h3>
              </Link>
            </Fade>
            <h4 style={{ fontSize: '20px' }}>
              Our reliable and effective stock screener gives you ideas on which stocks to trade based on technical analysis.
            </h4>
          </Col>
          <Col md={6} sm={12} xs={12} className="mb-5 text-center">
            <Fade top>
              <img src={JournalIcon} alt="Ringgit Lab Elite" style={{ width: '70px' }} />
              <Link to="/trading-journal" className="link-black">
                <h3 className="mt-4 font-weight-bold" style={{ fontSize: '24px' }}>
                  Trading Journal
                </h3>
              </Link>
            </Fade>
            <h4 style={{ fontSize: '20px' }}>
              Our intuitive trading journal will help you in improving your stock trading performance.
            </h4>
          </Col>
          <Col md={6} sm={12} xs={12} className="mb-5 text-center">
            <Fade top>
              <img src={PaperIcon} alt="Ringgit Lab Elite" style={{ width: '70px' }} />
              <Link to="/paper-trading" className="link-black">
                <h3 className="mt-4 font-weight-bold" style={{ fontSize: '24px' }}>
                  Paper Trading
                </h3>
              </Link>
            </Fade>
            <h4 style={{ fontSize: '20px' }}>
              Our paper trading will let you practice trading using virtual money based on our intelligent simulation.
            </h4>
          </Col>
          <Col md={6} sm={12} xs={12} className="mb-5 text-center">
            <Fade top>
              <img src={ChannelIcon} alt="Ringgit Lab Elite" style={{ width: '70px' }} />
              <div className="link-black" onClick={toggleModalWatchList} style={{ cursor: 'pointer' }}>
                <h3 className="mt-4 font-weight-bold" style={{ fontSize: '24px' }}>
                  Watch List
                </h3>
              </div>
            </Fade>
            <h4 style={{ fontSize: '20px' }}>
              Save your favorite stock to your watch list and get notified{' '}
              <span className="success">(soon)</span>{' '}when your favorite stocks hit at certain price.
            </h4>
          </Col>
          <Col md={6} sm={12} xs={12} className="mb-5 text-center">
            <Fade top>
              <img src={MaterialIcon} alt="Ringgit Lab Elite" style={{ width: '70px' }} />
              <div className="link-black" onClick={toggleModalLearning} style={{ cursor: 'pointer' }}>
                <h3 className="mt-4 font-weight-bold" style={{ fontSize: '24px' }}>
                  Learning Materials
                </h3>
              </div>
            </Fade>
            <h4 style={{ fontSize: '20px' }}>
              Learn directly about stock trading with the learning materials prepared by various experienced remisiers.
            </h4>
          </Col>
          <Col md={6} sm={12} xs={12} className="mb-5 text-center">
            <Fade top>
              <img src={AccountIcon} alt="Ringgit Lab Elite" style={{ width: '70px' }} />
              <a href="https://www.ringgitlab.com/market-news" className="link-black" style={{ cursor: 'pointer' }} rel="noopener noreferrer" target="_blank">
                <h3 className="mt-4 font-weight-bold" style={{ fontSize: '24px' }}>
                  Market News
                </h3>
              </a>
            </Fade>
            <h4 style={{ fontSize: '20px' }}>
              Latest news related to the local and international stock markets to help you making trading decision. 
            </h4>
          </Col>
        </Row>
        <div className="mt-5 text-center">
          <a href="https://www.zulhilmizolkfli.com/mplus-online-cds-registration" className="mb-3 mr-3" rel="noopener noreferrer" target="_blank">
            <h3 className="mb-0">
              <Button className="primary-btn font-weight-bold header-pill">Open CDS account</Button>
            </h3>
          </a>
        </div>
      </Container>
      <WatchListModal 
        open={openModalWatchList}
        toggle={toggleModalWatchList}
      />
      <LearningMaterialsModal 
        open={openModalLearning}
        toggle={toggleModalLearning}
      />
    </>
  )
}

export default MainFeatures;