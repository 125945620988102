import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import MobileIcon from '../../assets/icons/online-advertising.png';
import GraphIcon from '../../assets/icons/bar-graph.png';
import KeyIcon from '../../assets/icons/key.png';
import ChartIcon from '../../assets/icons/pie-chart.png';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

const WhatToExpectSoon = () => {

  return(
    <>
      <Container className="pt-5 pb-5">
        <Slide bottom>
          <h2 className="text-center font-weight-bold">
            Coming Soon to Ringgit Lab Elite
          </h2>
          <h3 className="grey-color text-center mt-3" style={{ fontSize: '24px' }}>
            We're dedicated to provide you the best one-stop supporting platform and keep improving the platform while developing new features 
            to support your trading activities
          </h3>
        </Slide>
        <Row className="mt-5">
          <Col md={1} sm={2} xs={2} className="mb-5 align-self-center">
            <Fade bottom>
              <img src={KeyIcon} alt="Ringgit Lab Elite" style={{ width: '100%' }} />
            </Fade>
          </Col>
          <Col md={11} sm={10} xs={10} className="mb-5">
            <Fade bottom>
              <h3 className="mt-3 font-weight-bold" style={{ fontSize: '22px' }}>
                Stock Indicators
              </h3>
              <h4 className="grey-color" style={{ fontSize: '20px' }}>
                Ringgit Lab Elite will provide various stock screener where you can select based on certain indicator such as 52WH, Bollinger-band breakout, 20MA and many more.
              </h4>
            </Fade>
          </Col>
          <Col md={1} sm={2} xs={2} className="mb-5 align-self-center">
            <Fade bottom>
              <img src={ChartIcon} alt="Ringgit Lab Elite" style={{ width: '100%' }} />
            </Fade>
          </Col>
          <Col md={11} sm={10} xs={10} className="mb-5">
            <Fade bottom>
              <h3 className="mt-3 font-weight-bold" style={{ fontSize: '22px' }}>
                Automated Learning System
              </h3>
              <h4 className="grey-color" style={{ fontSize: '20px' }}>
                New to stock trading? Don't worry as our automated learning system will guide you so that you'll become an expert at the real market.
              </h4>
            </Fade>
          </Col>
          <Col md={1} sm={2} xs={2} className="mb-5 align-self-center">
            <Fade bottom>
              <img src={GraphIcon} alt="Ringgit Lab Elite" style={{ width: '100%' }} />
            </Fade>
          </Col>
          <Col md={11} sm={10} xs={10} className="mb-5">
            <Fade bottom>
              <h3 className="mt-3 font-weight-bold" style={{ fontSize: '22px' }}>
                Portfolio Analysis
              </h3>
              <h4 className="grey-color" style={{ fontSize: '20px' }}>
                We believe that there are times when we are can't make a move because we're holding stocks with big losses. 
                Our system will analyse your portfolio so that you can decide alternative ways to improve your portfolio performance.
              </h4>
            </Fade>
          </Col>
          <Col md={1} sm={2} xs={2} className="mb-5 align-self-center">
            <Fade bottom>
              <img src={MobileIcon} alt="Ringgit Lab Elite" style={{ width: '100%' }} />
            </Fade>
          </Col>
          <Col md={11} sm={10} xs={10} className="mb-5">
            <Fade bottom>
              <h3 className="mt-3 font-weight-bold" style={{ fontSize: '22px' }}>
                Mobile apps
              </h3>
              <h4 className="grey-color" style={{ fontSize: '20px' }}>
                We are planning to develop a mobile app for both IOS and Android soon. 
                We sure you'll love our mobile app. Imagine you can set alerts and get notified about market performance without having to monitor all the time.
              </h4>
            </Fade>
          </Col>
        </Row>
        <div className="mt-5 text-center">
          <a href="https://www.zulhilmizolkfli.com/mplus-online-cds-registration" className="mb-3 mr-3" rel="noopener noreferrer" target="_blank">
            <h3 className="mb-0">
              <Button className="primary-btn font-weight-bold header-pill">Open CDS account</Button>
            </h3>
          </a>
        </div>
      </Container>
    </>
  )
}

export default WhatToExpectSoon;