import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container
} from 'reactstrap';
import Footer from '../footer/Footer';
import Header from '../header/Header';

const TermsOfServicePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const greyText = {
    color: '#656973',
  };

  const title = {
    fontSize: '18px',
    marginTop: '40px',
  };

  return (
    <>
      <Helmet>
        <title>Ringgit Lab Elite: Terms of Service</title>
      </Helmet>
      <Header />
      <Container className="pt-5 pb-5">
        <h1 className="font-weight-bold mt-5">Terms and Conditions</h1>
        <div className="divider"></div>
        <div>
          <p style={greyText}>Last updated: December 1, 2021</p>
          <p>Thank you for using Ringgit Lab Elite.</p>
          <p>These Terms of Service ("Terms", "Terms and Conditions", "Terms of Service") constitute a legally binding agreement ("Agreement") between you and Ringgit Lab Elite (us", "we", or "our") governing your access to and use of Ringgit Lab Elite website, including any subdomains thereof, and any other websites through which Ringgit Lab Elite makes its services available (collectively, "Site") and all associated services (collectively, "Ringgit Lab Elite Services"). The Site and Ringgit Lab Elite Services together are hereinafter collectively referred to as the "Ringgit Lab Elite Platform".</p>
          <p>Please read these Terms and Conditions carefully before using the Ringgit Lab Elite Platform.</p>
          <p>Your access to and use of the Ringgit Lab Elite Platform is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Ringgit Lab Elite Platform.</p>
          <p>By accessing or using the Ringgit Lab Elite Platform you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Ringgit Lab Elite Platform.</p>

          <h2 style={title} className="font-weight-bold">Purchases</h2>
          <p>If you wish to purchase any product or service made available through the Ringgit Lab Elite Platform ("Purchase"), you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.</p>
          <p>You represent and warrant that: (i) you have the legal right to use any credit card(s) or other payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.</p>
          <p>By submitting such information, you grant us the right to provide the information to third parties for purposes of facilitating the completion of Purchases.</p>
          <p>Ringgit Lab Elite reserves the right to refuse or cancel your order at any time for certain reasons including but not limited to: product or service availability, errors in the description or price of the product or service, error in your order or other reasons.</p>
          <p>Ringgit Lab Elite reserves the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.</p>

          <h2 style={title} className="font-weight-bold">Availability, Errors and Inaccuracies</h2>
          <p>We are constantly updating our offerings of products and services on the Ringgit Lab Elite Platform. The products or services available on the Ringgit Lab Elite Platform may be mispriced, described inaccurately, or unavailable, and we may experience delays in updating information on the Ringgit Lab Elite Platform and in our advertising on other web sites.</p>
          <p>We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.</p>

          <h2 style={title} className="font-weight-bold">Contests, Sweepstakes and Promotions</h2>
          <p>Any contests, sweepstakes or other promotions (collectively, "Promotions") made available through the Ringgit Lab Elite Platform may be governed by rules that are separate from these Terms. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms, the Promotion rules will apply.</p>

          <h2 style={title} className="font-weight-bold">Content</h2>
          <p>The Ringgit Lab Elite Platform allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the Content that you post to the Ringgit Lab Elite Platform, including its legality, reliability, and appropriateness.</p>
          <p>By posting Content to the Ringgit Lab Elite Platform, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Ringgit Lab Elite Platform. You retain any and all of your rights to any Content you submit, post or display on or through the Ringgit Lab Elite Platform and you are responsible for protecting those rights. You agree that this license includes the right for us to make your Content available to other users of the Ringgit Lab Elite Platform, who may also use your Content subject to these Terms.</p>
          <p>You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Ringgit Lab Elite Platform does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person.</p>

          <h2 style={title} className="font-weight-bold">Accounts</h2>
          <p>When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on the Ringgit Lab Elite Platform.</p>
          <p>You are responsible for safeguarding the password that you use to access the Ringgit Lab Elite Platform and for any activities or actions under your password, whether your password is with the Ringgit Lab Elite Platform or a third-party service.</p>
          <p>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
          <p>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trade mark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</p>

          <h2 style={title} className="font-weight-bold">Copyright Policy</h2>
          <p>We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on the Ringgit Lab Elite Platform infringes the copyright or other intellectual property infringement ("Infringement") of any person.</p>
          <p>If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Ringgit Lab Elite Platform, you must submit your notice in writing to the attention of "Copyright Infringement" of elite@ringgitlab.com and include in your notice a detailed description of the alleged Infringement.</p>
          <p>You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing your copyright.</p>

          <h2 style={title} className="font-weight-bold">Intellectual Property</h2>
          <p>The Ringgit Lab Elite Platform and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Ringgit Lab Elite and its licensors. The Ringgit Lab Elite Platform is protected by copyright, trademark, and other laws of both the Malaysia and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Ringgit Lab Elite.</p>

          <h2 style={title} className="font-weight-bold">Links To Other Web Sites</h2>
          <p>The Ringgit Lab Elite Platform may contain links to third-party web sites or services that are not owned or controlled by Ringgit Lab Elite.</p>
          <p>Ringgit Lab Elite has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Ringgit Lab Elite shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
          <p>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</p>

          <h2 style={title} className="font-weight-bold">Termination</h2>
          <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
          <p>Upon termination, your right to use the Ringgit Lab Elite Platform will immediately cease. If you wish to terminate your account, you may simply discontinue using the Ringgit Lab Elite Platform.</p>

          <h2 style={title} className="font-weight-bold">Limitation Of Liability</h2>
          <p>In no event shall Ringgit Lab Elite, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Ringgit Lab Elite Platform; (ii) any conduct or content of any third party on the Ringgit Lab Elite Platform; (iii) any content obtained from the Ringgit Lab Elite Platform; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>
          <p>Ringgit Lab Elite's responsibility is limited to facilitating the availability of the Ringgit Lab Elite Platform.</p>

          <h2 style={title} className="font-weight-bold">Disclaimer</h2>
          <p>Your use of the Ringgit Lab Elite Platform is at your sole risk. The Ringgit Lab Elite Platform is provided on an "AS IS" and "AS AVAILABLE" basis. The Ringgit Lab Elite Platform is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>
          <p>Ringgit Lab Elite's subsidiaries, affiliates, and its licensors do not warrant that a) the Ringgit Lab Elite Platform will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Ringgit Lab Elite Platform is free of viruses or other harmful components; or d) the results of using the Ringgit Lab Elite Platform will meet your requirements.</p>
          <p>The Ringgit Lab Elite Platform is not an investment advisory service, nor a registered investment advisor or broker-dealer and does not purport to tell or suggest which securities or stocks customers should buy or sell for themselves. The analysts or affiliates may hold positions in the stocks or industries discussed on The Ringgit Lab Elite Platform. You understand and acknowledge that there is a very high degree of risk involved in trading securities. Ringgit Lab Elite, the authors, the publisher, and all affiliates of the Ringgit Lab Elite Platform assume no responsibility or liability for your trading and investment results. Factual statements on the Ringgit Lab Elite Platform's website, or in its publications, are made as of the date stated and are subject to change without notice.</p>
          <p>It should not be assumed that the methods, techniques, or indicators presented in the Ringgit Lab Elite Platform will be profitable or that they will not result in losses. Past results of any individual trader or trading system published by the writer are not indicative of future returns by that trader or system and are not indicative of future returns which be realized by you. In addition, the indicators, strategies, columns, articles, and all other features of the Ringgit Lab Elite Platform (collectively, the "Information") are provided for informational and educational purposes only and should not be construed as investment advice. Examples presented on The Ringgit Lab Elite Platform are for educational purposes only. Such set-ups are not solicitations of any order to buy or sell. Accordingly, you should not rely solely on the Information in making any investment. Rather, you should use the Information only as a starting point for doing additional independent research in order to allow you to form their own opinion regarding investments. You should always check with your licensed financial advisor and tax advisor to determine the suitability of any investment.</p>

          <h2 style={title} className="font-weight-bold">Governing Law</h2>
          <p>These Terms shall be governed and construed in accordance with the laws of Malaysia, without regard to its conflict of law provisions.</p>
          <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding the Ringgit Lab Elite Platform, and supersede and replace any prior agreements we might have between us regarding the Ringgit Lab Elite Platform.</p>

          <h2 style={title} className="font-weight-bold">Changes</h2>
          <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 15 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
          <p>By continuing to access or use the Ringgit Lab Elite Platform after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Ringgit Lab Elite Platform.</p>

          <h2 style={title} className="font-weight-bold">Contact Us</h2>
          <p className="pb-5">If you have any questions about these Terms, please contact us at elite@ringgitlab.com</p>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default TermsOfServicePage;