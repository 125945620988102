import React from 'react';
import { Container, Modal, ModalBody, ModalHeader } from 'reactstrap';
import MaterialsImg from '../../assets/learning-materials.png';

interface Props {
  open: boolean;
  toggle: () => void;
}

export const LearningMaterialsModal: React.FC<Props> = (props) => {

  return (
    <>
      <Modal isOpen={props.open} toggle={props.toggle} size="lg">
        <ModalHeader toggle={props.toggle}>
          <span style={{ fontSize: '22px' }} className="font-weight-bold pt-2 pb-2">
            Free Learning Materials
          </span>
        </ModalHeader>
        <ModalBody  className="pb-5 mb-5">
          <Container className="pt-3 pb-5">
            <h3 style={{ fontSize: '18px' }} className="grey-color mb-3">
              Prepare yourself with basic knowledge to stock trading. Familiarise yourself with the online trading platform to make sure your trading activities go smoothly. 
              We also provide learning materials from various experienced remisiers.
            </h3>
            <img alt="Ringgit Lab Elite" src={MaterialsImg} style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.06) 0px 1rem 1rem', height: '600px', objectFit: 'cover', objectPosition: 'left top', borderRadius: '10px' }} />
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
}