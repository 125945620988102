import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import Logo from '../../assets/ringgit-lab-elite-brand.png';
import HoverControlledDropdown from '../shared/HoverControlledDropdown';

const Header = () => {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return(
    <>
      <Navbar className="fixed-navbar pt-3 pb-3" expand="md">
        <Link to="/" className="text-decoration-none" style={{ width: '200px' }}>
          <img src={Logo} alt="Ringgit Lab Elite Logo" className="w-100" />
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={open} navbar>
          <Nav className="mt-0 mr-auto" navbar>
            <HoverControlledDropdown>
              <NavItem className="toggle-space">
                <DropdownToggle tag="a" className="nav-item-left" style={{ cursor: 'pointer' }}>
                  Features
                </DropdownToggle>
              </NavItem>
              <DropdownMenu>
                <DropdownItem>
                  <Link to="/stock-screener" className="nav-item-dropdown" onClick={toggle}>
                    Stock Screener
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/trading-journal" className="nav-item-dropdown" onClick={toggle}>
                    Trading Journal
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link to="/paper-trading" className="nav-item-dropdown" onClick={toggle}>
                    Paper Trading
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </HoverControlledDropdown>
            <NavItem className="toggle-space">
              <a href="https://www.ringgitlab.com/stocks" className="nav-item-left" rel="noopener noreferrer" target="_blank" onClick={toggle}>
                Stock Search
              </a>
            </NavItem>
            <NavItem className="toggle-space">
              <a href="https://www.ringgitlab.com/market-news" className="nav-item-left" rel="noopener noreferrer" target="_blank" onClick={toggle}>
                Market News
              </a>
            </NavItem>
            <HoverControlledDropdown>
              <NavItem className="toggle-space">
                <DropdownToggle tag="b" className="nav-item-left" style={{ cursor: 'pointer' }}>
                  FAQs
                </DropdownToggle>
              </NavItem>
              <DropdownMenu>
                <DropdownItem>
                  <Link to="/how-to-sign-up" className="nav-item-dropdown" onClick={toggle}>
                    How to sign up Ringgit Lab Elite?
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <a href="https://ringgitlabelite.notion.site/Ringgit-Lab-Elite-FAQs-ba4df99417764761848f853bf56c527c" className="nav-item-dropdown" rel="noopener noreferrer" target="_blank" onClick={toggle}>
                    Help Center (English)
                  </a>
                </DropdownItem>
                <DropdownItem>
                  <a href="https://ringgitlabelite.notion.site/Soalan-Lazim-Berkenaan-Ringgit-Lab-Elite-5093647fdc9e420299e4fb353715902f" className="nav-item-dropdown" rel="noopener noreferrer" target="_blank" onClick={toggle}>
                    Help Center (Bahasa)
                  </a>
                </DropdownItem>
              </DropdownMenu>
            </HoverControlledDropdown>
          </Nav>
          <Nav className="mt-0 ml-auto" navbar>
            <NavItem className="toggle-space">
              <a href="https://www.ringgitlab.com/login/ringgit-lab-elite" className="nav-item" onClick={toggle}>Log In</a>
            </NavItem>
            <NavItem className="toggle-space">
              <a href="https://www.zulhilmizolkfli.com/mplus-online-cds-registration" className="header-pill" rel="noopener noreferrer" target="_blank">
                Open CDS account
              </a>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </>
  )
}

export default Header;