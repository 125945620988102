import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import ScreenerImg from '../../assets/screener.png';
import BankingIcon from '../../assets/icons/banking.png';
import StatisticIcon from '../../assets/icons/statistics.png';
import KeyIcon from '../../assets/icons/key.png';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

const StockScreener = () => {

  return(
    <>
      <Container className="pt-5 pb-5">
        <Slide bottom>
          <h1 className="text-center mt-5 mb-3 font-weight-bold">
            KLSE Potential Stock Screener
          </h1>
          <h2 className="mt-3 pb-4 grey-color text-center" style={{ fontSize: '24px' }}>
            Our potential stock screener has more than 90% of winning-rate
          </h2>
          <img alt="Ringgit Lab Elite: Stock Screener" src={ScreenerImg} style={{ width: '100%' }} />
        </Slide>
        <Slide bottom>
          <div className="divider mt-5"></div>
          <h2 className="mt-5 pt-5 font-weight-bold text-center" style={{ fontSize: '32px' }}>
            How Ringgit Lab Elite Potential Stock Screener works?
          </h2>
          <div className="pt-5">
            <h3 style={{ fontSize: '22px' }} className="font-weight-bold">
              1. Live tab
            </h3>
            <h4 style={{ fontSize: '20px', color: '#656973' }} className="mt-3">
              The list of potential stocks that might gain profits during that trading day. This screener is based on 15-minutes delayed data during the market opens.
            </h4>
          </div>
          <div className="mt-5">
            <h3 style={{ fontSize: '22px' }} className="font-weight-bold">
              2. BTST tab
            </h3>
            <h4 style={{ fontSize: '20px', color: '#656973' }} className="mt-3">
              The list of BTST potential stocks that might gain profits on the next trading day (screen after 4 pm).
            </h4>
          </div>
          <div className="mt-5">
            <h3 style={{ fontSize: '22px' }} className="font-weight-bold">
              3. BTST Elite tab
            </h3>
            <h4 style={{ fontSize: '20px', color: '#656973' }} className="mt-3">
              The list of BTST Elite potential stocks that might gain profits on the next trading day (screen after 4:30 pm).
            </h4>
          </div>
          <div className="mt-5">
            <h3 style={{ fontSize: '22px' }} className="font-weight-bold">
              4. Close tab
            </h3>
            <h4 style={{ fontSize: '20px', color: '#656973' }} className="mt-3">
              The list of potential stocks that might gain profits on the next trading day based on the trading activities on that day (screen after the market closes).
            </h4>
          </div>
        </Slide>
        <div className="mt-5 text-center">
          <a href="https://www.zulhilmizolkfli.com/mplus-online-cds-registration" className="mb-3 mr-3" rel="noopener noreferrer" target="_blank">
            <h3 className="mb-0">
              <Button className="primary-btn font-weight-bold header-pill">Open CDS account</Button>
            </h3>
          </a>
        </div>
        <div className="divider mt-5"></div>
        <div className="pt-3 pb-5">
          <Fade bottom>
            <h2 className="mt-5 font-weight-bold text-center" style={{ fontSize: '32px' }}>
              How Ringgit Lab Elite chooses the potential stocks?
            </h2>
            <h3 style={{ fontSize: '24px', color: '#656973' }} className="mt-3 text-center">
              Ringgit Lab Elite Potential Stock Screener is developed with guidance, assistance and insights from multiple experienced traders, 
              the techniques that are proven to result more than 90% winning-rate.
            </h3>
            <Row className="mt-5">
              <Col md={4} sm={12} xs={12} className="mb-5 text-center">
                <img src={BankingIcon} alt="Ringgit Lab Icon" style={{ width: '70px' }} />
                <h3 className="mt-3 font-weight-bold" style={{ fontSize: '22px' }}>
                  Market maker move indicator
                </h3>
              </Col>
              <Col md={4} sm={12} xs={12} className="mb-5 text-center">
                <img src={StatisticIcon} alt="Ringgit Lab Icon" style={{ width: '70px' }} />
                <h3 className="mt-3 font-weight-bold" style={{ fontSize: '22px' }}>
                  Prediction based on current trend
                </h3>
              </Col>
              <Col md={4} sm={12} xs={12} className="mb-5 text-center">
                <img src={KeyIcon} alt="Ringgit Lab Icon" style={{ width: '70px' }} />
                <h3 className="mt-3 font-weight-bold" style={{ fontSize: '22px' }}>
                  Technical & Fundamental analysis
                </h3>
              </Col>
            </Row>
            <div className="mt-5 text-center">
              <a href="https://www.zulhilmizolkfli.com/mplus-online-cds-registration" className="mb-3 mr-3" rel="noopener noreferrer" target="_blank">
                <h3 className="mb-0">
                  <Button className="primary-btn font-weight-bold header-pill">Open CDS account</Button>
                </h3>
              </a>
            </div>
          </Fade>
          <div className="divider mt-5"></div>
          <div className="mt-5">
            <Fade bottom>
              <h3 style={{ fontSize: '24px', textDecoration: 'underline' }} className="font-weight-bold">Explanations</h3>
              <h4 style={{ fontSize: '22px' }} className="font-weight-bold mt-5">Market Maker Move Indicator</h4>
              <h5 style={{ fontSize: '20px', color: '#656973' }}>
                A market maker (or well-known as Jerung in Malaysia) is an individual market participant or member firm of an 
                exchange that also buys and sells securities for its own account, at prices it displays in its exchange's trading 
                system, with the primary goal of profiting on the bid-ask spread, which is the amount by which the ask price exceed 
                the bid price a market asset.
              </h5>
              <h5 style={{ fontSize: '20px', color: '#656973' }} className="mt-3">
                As the name suggests, market makers "create the market". In other words, they create liquidity in the market by 
                being readily available to buy and sell securities. This creates liquidity within the market. Without market makers, 
                the market would be relatively illiquid and other trades would be impacted.
              </h5>
              <h5 style={{ fontSize: '20px', color: '#656973' }} className="mt-3">
                As a retail traders, we want to follow the steps of market makers. We want to buy securities when they buy, and 
                sell the securities before they sell. Easy right?
              </h5>
              <h4 style={{ fontSize: '22px' }} className="font-weight-bold mt-5">Predictions based on current value</h4>
              <h5 style={{ fontSize: '20px', color: '#656973' }}>
                Ringgit Lab Stock Screener tries to understand where a stock's price is going based on market behavior as evidenced in its 
                market statistics (presented in charts, price and trading volume data). It determines where the price of the stock is trending. 
                Some of the values our screener takes are:
              </h5>
              <ul className="mt-4">
                <li style={{ fontSize: '20px' }}>
                  <h5 style={{ fontSize: '20px', color: '#656973' }}>
                    The ratio of buy value and sell value
                  </h5>
                </li>
                <li style={{ fontSize: '20px' }}>
                  <h5 style={{ fontSize: '20px', color: '#656973' }}>
                    Transaction volume
                  </h5>
                </li>
                <li style={{ fontSize: '20px' }}>
                  <h5 style={{ fontSize: '20px', color: '#656973' }}>
                    Buy Value / Sell Value
                  </h5>
                </li>
                <li style={{ fontSize: '20px' }}>
                  <h5 style={{ fontSize: '20px', color: '#656973' }}>
                    Traded volume
                  </h5>
                </li>
              </ul>
              <h4 style={{ fontSize: '22px' }} className="font-weight-bold mt-5">Technical & Fundamental analysis</h4>
              <h5 style={{ fontSize: '20px', color: '#656973' }}>
                Ringgit Lab Elite searches for good quality stocks based on both technical and fundamental analysis. 
                Ringgit Lab Elite Potential Stock Screener screens based on:
              </h5>
              <ul className="mt-4">
                <li style={{ fontSize: '20px' }}>
                  <h5 style={{ fontSize: '20px', color: '#656973' }}>
                    Quarter report net profit
                  </h5>
                </li>
                <li style={{ fontSize: '20px' }}>
                  <h5 style={{ fontSize: '20px', color: '#656973' }}>
                    Earning Per Share (EPS)
                  </h5>
                </li>
                <li style={{ fontSize: '20px' }}>
                  <h5 style={{ fontSize: '20px', color: '#656973' }}>
                    Profit / Earning Ratio
                  </h5>
                </li>
                <li style={{ fontSize: '20px' }}>
                  <h5 style={{ fontSize: '20px', color: '#656973' }}>
                    Exponential Moving Average 5 days period
                  </h5>
                </li>
                <li style={{ fontSize: '20px' }}>
                  <h5 style={{ fontSize: '20px', color: '#656973' }}>
                  Exponential Moving Average 20 days period
                  </h5>
                </li>
                <li style={{ fontSize: '20px' }}>
                  <h5 style={{ fontSize: '20px', color: '#656973' }}>
                    Moving Average Convergences Divergences (MACD)
                  </h5>
                </li>
              </ul>
            </Fade>
          </div>
          <div className="mt-5 text-center text-md-left">
            <a href="https://www.zulhilmizolkfli.com/mplus-online-cds-registration" className="mb-3 mr-3" rel="noopener noreferrer" target="_blank">
              <h3 className="mb-0">
                <Button className="primary-btn font-weight-bold header-pill">Open CDS account</Button>
              </h3>
            </a>
          </div>
        </div>
      </Container>
    </>
  )
}

export default StockScreener;