import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import StatisticIcon from '../../assets/icons/statistics.png';
import StatementIcon from '../../assets/icons/income-statement.png';
import FinanceIcon from '../../assets/icons/finance.png';
import GraphIcon from '../../assets/icons/pie-chart.png';
import SecurityIcon from '../../assets/icons/security.png';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

const HowWeHelp = () => {

  const colorBg = {
    backgroundColor: 'rgb(250, 250, 250)',
  };

  return(
    <div style={colorBg}>
      <Container className="pt-5 pb-5">
        <Slide bottom>
          <h2 className="text-center font-weight-bold">
            How Ringgit Lab Elite helps you?
          </h2>
          <h3 className="grey-color text-center mt-3" style={{ fontSize: '24px' }}>
            Ringgit Lab Elite is a one-stop center for your investment solution. We offer a set of trading tools 
            to help you in your trading activities.
          </h3>
        </Slide>
        <Row className="mt-5">
          <Col md={1} sm={2} xs={2} className="mb-5 align-self-center">
            <Fade bottom>
              <img alt="Ringgit Lab Elite" src={SecurityIcon} style={{ width: '100%' }} />
            </Fade>
          </Col>
          <Col md={11} sm={10} xs={10} className="mb-5">
            <Fade bottom>
              <p className="mb-0 grey-color">Step 1</p>
              <h4 className="font-weight-bold" style={{ fontSize: '22px' }}>Learning Materials</h4>
              <h4 className="grey-color mt-3" style={{ fontSize: '20px' }}>
                Prepare yourself with basic knowledge to stock trading. Familiarise yourself with the online trading platform to make sure your 
                trading activities go smoothly. We provide learning materials from various experienced remisiers.
              </h4>
            </Fade>
          </Col>
          <Col md={1} sm={2} xs={2} className="mb-5 align-self-center">
            <Fade bottom>
              <img alt="Ringgit Lab Elite" src={StatementIcon} style={{ width: '100%' }} />
            </Fade>
          </Col>
          <Col md={11} sm={10} xs={10} className="mb-5">
            <Fade bottom>
              <p className="mb-0 grey-color">Step 2</p>
              <h4 className="font-weight-bold" style={{ fontSize: '22px' }}>Paper Trading</h4>
              <h4 className="grey-color mt-3" style={{ fontSize: '20px' }}>
                Practice yourself with virtual paper trading simulators before trading with actual money at actual markets. Paper 
                trading will help you prepare with confidence and strategies.{' '}
                <span>
                  <Link to="/paper-trading" className="link-text">Read here to learn more.</Link>
                </span>
              </h4>
            </Fade>
          </Col>
          <Col md={1} sm={2} xs={2} className="mb-5 align-self-center">
            <Fade bottom>
              <img alt="Ringgit Lab Elite" src={StatisticIcon} style={{ width: '100%' }} />
            </Fade>
          </Col>
          <Col md={11} sm={10} xs={10} className="mb-5">
            <Fade bottom>
              <p className="mb-0 grey-color">Step 3</p>
              <h4 className="font-weight-bold" style={{ fontSize: '22px' }}>Potential Stock Screener</h4>
              <h4 className="grey-color mt-3" style={{ fontSize: '20px' }}>
                Utilize our stock screener to get some trading idea and get notified of potential stocks that you can buy 
                including the trading plan such as resistant and support level.{' '}
                <span>
                  <Link to="/stock-screener" className="link-text">Read here to learn more.</Link>
                </span>
              </h4>
            </Fade>
          </Col>
          <Col md={1} sm={2} xs={2} className="mb-5 align-self-center">
            <Fade bottom>
              <img alt="Ringgit Lab Elite" src={FinanceIcon} style={{ width: '100%' }} />
            </Fade>
          </Col>
          <Col md={11} sm={10} xs={10} className="mb-5">
            <Fade bottom>
              <p className="mb-0 grey-color">Step 4</p>
              <h4 className="font-weight-bold" style={{ fontSize: '22px' }}>Broker's Trading Platform</h4>
              <h4 className="grey-color mt-3" style={{ fontSize: '20px' }}>
                Any purchase and sell order will be executed online on trading platform by yourself.{' '}
                <span>
                  <a href="https://www.zulhilmizolkfli.com" className="link-text" rel="noopener noreferrer" target="_blank"> Visit Zulhilmi Zolkfli website to learn more.</a>
                </span>
              </h4>
            </Fade>
          </Col>
          <Col md={1} sm={2} xs={2} className="mb-5 align-self-center">
            <Fade bottom>
              <img alt="Ringgit Lab Elite" src={GraphIcon} style={{ width: '100%' }} />
            </Fade>
          </Col>
          <Col md={11} sm={10} xs={10} className="mb-5">
            <Fade bottom>
              <p className="mb-0 grey-color">Step 5</p>
              <h4 className="font-weight-bold" style={{ fontSize: '22px' }}>Trading Journal</h4>
              <h4 className="grey-color mt-3" style={{ fontSize: '20px' }}>
                Discipline yourself to create a trading journal after every trade. Keep track of your progress, analyze your 
                performance and plan for the next strategies that suit you well in trading stocks.{' '}
                <span>
                  <Link to="/trading-journal" className="link-text">Read here to learn more.</Link>
                </span>
              </h4>
            </Fade>
          </Col>
        </Row>
        <div className="mt-5 text-center">
          <a href="https://www.zulhilmizolkfli.com/mplus-online-cds-registration" className="mb-3 mr-3" rel="noopener noreferrer" target="_blank">
            <h3 className="mb-0">
              <Button className="primary-btn font-weight-bold header-pill">Open CDS account</Button>
            </h3>
          </a>
        </div>
      </Container>
    </div>
  )
}

export default HowWeHelp;